<template>
  <div class="wait-commend bsbb">
    <div class="all-commend df aic jcsb font-size-24 p15 bsbb mb20" @click="$router.push('/user/myCommend')">
      <div class="df aic">
        <img src="../../assets/images/xg/commend.png" class="mr20" />
        <p class="fw7">我的評價</p>
      </div>
      <van-icon name="arrow" @click="$router.push('/user1')" />
    </div>
    <div class="order-list df fdc">
      <div class="item mb20 p15 bsbb" v-for="(v, i) in unfinishedOrder" :key="i">
        <div class="df aic jcsb font-size-24 fw7" style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px">
          <p>ROCshop</p>
          <p>{{ changeTime(v.create_time * 1000) }} 下单</p>
        </div>
        <div class="df aic jcsb text p15 bsbb">
          <div class="df aic font-size-20">
            <img :src="v.order_img" class="mr20" />
            <p style="color: #77d477">写评价,商品好坏就差你的发声了</p>
          </div>
        <!--  <van-button type="default" @click="goCommend(v.id)">评价</van-button> -->
        </div>
      </div>
      <!-- <div class="item mb20 p15 bsbb">
                        <div class="df aic jcsb font-size-24 fw7" style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px">
                          <p>菜大全鲜果蔬菜成都店</p>
                          <p>2022-12-23 00:00 下单</p>
                        </div>
                        <div class="df aic jcsb text p15 bsbb">
                          <div class="df aic font-size-20">
                            <img src="../../assets/images/xg/list5.png" class="mr20" />
                            <p style="color: #77d477">写评价,生鲜好坏就差你的发声了</p>
                          </div>
                          <van-button type="default">评价</van-button>
                        </div>
                      </div> -->
    </div>
  </div>
</template>

<script>
import { getMyOrderReq } from "@/utils/api.js";
export default {
  data() {
    return {
      unfinishedOrder: [],
    };
  },
  methods: {
    goCommend(n) {
      this.$router.push({
        name: "evaluateContent",
        query: {
          id: n,
        },
      });
    },
    changeTime(date) {
      let time = new Date(date);
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      return [y, m, d].map((v) => String(v).padStart(2, "0")).join("-");
    },
    // export const formatYMD = (date) => {
    //   let time = new Date(date)
    //   let y = time.getFullYear()
    //   let M = time.getMonth() + 1
    //   let d = time.getDate()

    //   return [y, M, d].map(v => String(v).padStart(2, '0')).join('-')
    // }
  },
  mounted() {
    getMyOrderReq({ page: 1, page_size: 50 }).then((res) => {
      this.orderList = res.data.list;
      this.unfinishedOrder = this.orderList.filter(
        (v) => v.payment_status == 50
      );
      console.log(this.unfinishedOrder);
    });
  },
};
</script>

<style lang="scss" scoped>
.wait-commend {
  width: 100%;
  height: 100%;

  .all-commend {
    width: 690px;
    height: 86px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);

    img {
      width: 35.99px;
      height: 36px;
    }
  }

  .order-list {
    .item {
      width: 684px;
      height: 213px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);

      .text {
        img {
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }

        .van-button {
          width: 129px;
          height: 57px;
          border-radius: 15px;
        }
      }
    }
  }
}
</style>
